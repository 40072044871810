import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Authors from "../../containers/Authors"
import Banner from "../../containers/Banner"
import Consultation from "../../containers/Consultation"
import Layout from "../../containers/Layout"
import SEO from "../../components/SEO"
import { ContentWrapper } from "../../containers/alpacked.style"

const AuthorsPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query Authors {
      allPrismicAuthor {
        edges {
          node {
            uid
            data {
              name {
                html
              }
              occupation {
                text
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 72) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              details {
                html
              }
              linkedin_profile {
                url
              }
            }
          }
        }
      }
      prismicAuthors {
        data {
          seo_description {
            text
          }
          seo_short_description {
            text
          }
          seo_title {
            text
          }
          image_link_preview {
            fluid {
              src
            }
          }
          title {
            html
          }
          image {
            url
          }
        }
      }
    }
  `)

  const { edges } = data.allPrismicAuthor
  const {
    title,
    image,
    seo_description,
    seo_title,
    image_link_preview,
    seo_short_description,
  } = data.prismicAuthors.data

  return (
    <>
      <SEO
        image={image_link_preview.fluid?.src}
        location={location}
        title={seo_title.text}
        shortDesc={seo_short_description.text}
        description={seo_description.text}
      />
      <Layout>
        <ContentWrapper>
          <Banner title={title.html} breadcrumbs image={image.url} />
          <Authors content={edges} />
          <Consultation />
        </ContentWrapper>
      </Layout>
    </>
  )
}

export default AuthorsPage
