import SectionWrapper, { Author, Content, Inner, Photo } from "./authors.style"

import Arrow from "../../common/assets/image/alpacked/services/arrow.svg"
import Container from "../../components/UI/Container"
import Heading from "../../components/Heading"
import { Icon } from "react-icons-kit"
import Img from "gatsby-image"
import { Link } from "gatsby"
import React from "react"
import Text from "../../components/Text"
import { linkedin } from "react-icons-kit/fa/linkedin"
import striptags from "striptags"

const Authors = ({ content }) => {
  return (
    <SectionWrapper>
      {content.map((author, index) => {
        const { data, uid } = author.node

        return (
          <Container width="1220px" key={index} className="container">
            <Inner>
              <Photo>
                <Img
                  fadeIn={false}
                  loading="lazy"
                  fluid={data.image.localFile?.childImageSharp.fluid}
                  className="image"
                  alt="author Image"
                />
                <Link to="/contacts/" className="questionLink">
                  <Text content="ASK A QUESTION" className="askQuestion" />
                  <img
                    loading="lazy"
                    src={Arrow}
                    alt="arrowLight"
                    className="arrow"
                  />
                </Link>
              </Photo>
              <Content>
                <div>
                  <Author>
                    <Heading
                      as={data.name.html.substring(1, 3)}
                      content={striptags(data.name.html)}
                    />
                    <a
                      aria-label="Linkedin"
                      href={data.linkedin_profile.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon icon={linkedin} />
                    </a>
                  </Author>
                  <Text content={data.occupation.text} className="occupation" />
                  <hr className="seperator" />
                  <div
                    className="details"
                    dangerouslySetInnerHTML={{ __html: data.details.html }}
                  />
                </div>
                <Link to={`/blog/authors/${uid}/`} className="blogLink">
                  <Text
                    content={`See all articles by ${striptags(
                      data.name.html.replace(/ .*/, "")
                    )}`}
                    className="allArticles"
                  />
                  <img
                    loading="lazy"
                    src={Arrow}
                    alt="arrowLight"
                    className="arrow"
                  />
                </Link>
              </Content>
            </Inner>
          </Container>
        )
      })}
    </SectionWrapper>
  )
}

export default Authors
