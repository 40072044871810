import styled from "styled-components"
import { themeGet } from "@styled-system/theme-get"

const SectionWrapper = styled.section`
  margin: -135px 0px 120px 0px;
  position: relative;
  z-index: 0;
  @media only screen and (max-width: 1220px) {
    margin: -120px 0 120px;
  }
  @media only screen and (max-width: 990px) {
    margin: -200px 0 80px;
  }
  @media only screen and (max-width: 480px) {
    margin: -280px 0 50px;
  }

  .container {
    margin-bottom: 32px;
  }
`

export const Inner = styled.div`
  background-color: ${themeGet("colors.lightGrey")};
  padding: 80px 80px;
  display: flex;

  @media only screen and (max-width: 990px) {
    flex-direction: column;
    padding: 60px 60px;
  }
  @media only screen and (max-width: 480px) {
    flex-direction: column;
    padding: 30px 30px;
  }
`

export const Author = styled.div`
  display: flex;
  align-items: center;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    color: ${themeGet("colors.mainBlue")};
    position: relative;
    padding: 5px;
    transition: all 0.3s ease;
    i {
      position: relative;
      z-index: 1;

      svg {
        width: 24px;
        height: auto;
      }
    }

    &:hover {
      color: ${themeGet("colors.mainBlueHover")};
    }
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 75%;
  min-height: 358px;
  @media only screen and (max-width: 990px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 480px) {
    min-height: 0px;
  }
  .blogLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 0;
    border-top: 1px solid ${themeGet("colors.mainBlue")};
    border-bottom: 1px solid ${themeGet("colors.mainBlue")};
    img {
      width: 32px;
    }
  }
  .seperator {
    border-top: 2px solid ${themeGet("colors.mainBlue")};
    margin: 16px 0;
    width: 40px;
    margin-left: 0;
    @media only screen and (max-width: 480px) {
      margin: 16px 0;
    }
  }
  h2,
  h1,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 0px;
    margin-right: 30px;
    @media only screen and (max-width: 990px) {
      font-size: 24px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 20px;
      line-height: 28px;
    }
  }
  .occupation {
    font-weight: 300;
    color: ${themeGet("colors.black")};
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0px;
    margin-top: 8px;
    @media only screen and (max-width: 990px) {
      font-size: 16px;
      margin-top: 4px;
    }
  }
  .details {
    color: ${themeGet("colors.black")};
    font-weight: 300;

    line-height: 28px;
    margin-bottom: 0px;
    @media only screen and (max-width: 990px) {
      margin-bottom: 32px;
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 16px;
      line-height: 28px;
    }

    p {
      font-size: 16px;
    }
  }
  .allArticles {
    color: ${themeGet("colors.mainBlue")};
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
    @media only screen and (max-width: 990px) {
      font-size: 18px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
`

export const Photo = styled.div`
  max-width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 128px;
  min-height: 358px;
  margin-top: 16px;
  @media only screen and (max-width: 990px) {
    width: 100%;
    margin-top: 0px;
  }
  @media only screen and (max-width: 480px) {
    min-height: 0px;
  }
  .questionLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 0;
    border-top: 1px solid ${themeGet("colors.mainBlue")};
    border-bottom: 1px solid ${themeGet("colors.mainBlue")};
    img {
      width: 32px;
    }
  }
  .image {
    width: 200px;
    border-radius: 50%;
    margin: 0 auto;
    @media only screen and (max-width: 1220px) {
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 480px) {
      width: 60%;
    }
  }
  .askQuestion {
    color: ${themeGet("colors.mainBlue")};
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 0px;
    @media only screen and (max-width: 480px) {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 990px) {
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 40px;
    justify-content: flex-start;
  }
  @media only screen and (max-width: 770px) {
    min-width: 100%;
  }
  @media only screen and (max-width: 480px) {
    min-width: 100%;
    margin-bottom: 20px;
  }
`

export default SectionWrapper
